import { webServiceApi, engineServiceApi } from '../service';
export default {
  // 获取子筛选任务列表接口
  taskGetsSub(params) {
    return webServiceApi.post('/index.php/Task/gets_sub', params);
  },

  // 获取该画质检测任务优化率和替换成功与否信息
  getErrorDetectionOptimizeInfo(params) {
    return engineServiceApi.post('/api/sopt/simplifier/', params);
  },

  //
  operateAsyncTask(urlEnd, params) {
    return engineServiceApi.post(`/api/sopt/simplifier/${urlEnd}`, params);
  },

  // 获取筛选任务结果的图片文件名列表
  getReplayTestList(params, config) {
    return engineServiceApi.post(
      '/api/sopt/simplifier/getReplayTestList',
      params,
      config
    );
  },

  // 获取筛选变体shader代码
  getVariantShaderCode(params) {
    return engineServiceApi.post(
      '/api/sopt/simplifier/getVariantShaderCode',
      params
    );
  },

  // 下载
  downloadFile(params, config) {
    return engineServiceApi.post(
      '/api/sopt/simplifier/downloadFile',
      params,
      config
    );
  },

  // 获取指定Shader信息
  getSpecifiedShader(params) {
    return engineServiceApi.post(
      '/api/sopt/simplifier/getSpecifiedShader',
      params
    );
  },

  // 获取shader信息总数接口（获取总数或为某个tag的总数）
  getShaderCount(params) {
    return engineServiceApi.post('/api/sopt/simplifier/getShaderCount', params);
  },

  // 以某种feature获取某种device_type下shader列表数量（针对id和comment的模糊搜索）
  getShaderCountWithFeature(params) {
    return engineServiceApi.post(
      '/api/sopt/simplifier/getShaderCountWithFeature',
      params
    );
  },

  // 以某种feature获取shader列表（针对id和tag的搜索）
  getPidInListWithFeature(params) {
    return engineServiceApi.post(
      '/api/sopt/simplifier/getPidInListWithFeature',
      params
    );
  },

  // 更新shader备注信息接口
  editShader(params) {
    return engineServiceApi.post('/api/sopt/simplifier/editShader', params);
  },

  // 查看shader源代码
  getOriginalShaderCode(params) {
    return engineServiceApi.post(
      '/api/sopt/simplifier/getOriginalShaderCode',
      params
    );
  },

  // 获取 highlight 图片文件列表
  getHighlightList(params) {
    return engineServiceApi.post(
      '/api/sopt/simplifier/getHighlightList',
      params
    );
  },

  // 获取任务列表接口
  taskGets(params, config) {
    return webServiceApi.post(
      '/index.php/Task/gets?page=1&pageSize=9999',
      params,
      config
    );
  },

  // 获取 snapshot 压缩图片列表
  getSnapshotCmpList(params) {
    return engineServiceApi.post(
      '/api/sopt/simplifier/getSnapshotCmpList',
      params
    );
  },

  // 请求获取开始帧数
  getAutomaticallyStartFrameNo(params) {
    return engineServiceApi.post(
      '/api/sopt/simplifier/getAutomaticallyStartFrameNo',
      params
    );
  },

  // 获取用户设备型号
  getUserDeviceModel(params) {
    return engineServiceApi.post(
      '/api/sopt/simplifier/getUserDeviceModel',
      params
    );
  },

  // 添加一个简化任务
  addSimplifyTask(params) {
    return engineServiceApi.post(
      '/api/sopt/simplifier/addSimplifyTask',
      params
    );
  },

  // 获取图表数据接口
  getChart(params) {
    return engineServiceApi.post('/api/sopt/simplifier/getChart', params);
  },

  // 添加筛选任务
  addReplayTestTask(params) {
    return engineServiceApi.post(
      '/api/sopt/simplifier/addReplayTestTask',
      params
    );
  },

  // get请求示例
  getExample(params) {
    return webServiceApi.get('/xxxx/xxx', { params });
  },
};
