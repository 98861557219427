<template>
  <a-layout class="rays_tuner_simplifytool_home">
    <a-row class="header" type="flex">
      <a-col flex="400px">
        <div class="header_left">
          <svg-icon class="back-icon" icon-class="chevron-left" @click="pre" />
          <div class="select_shader" @click="selectChange" v-clickoutside:select_shader="">
            <span> Shader{{ curShader.shader_id }} </span>
            <svg-icon class="down" icon-class="chevron-down" />
          </div>
          <div class="tips" @dblclick="remarkClick">
            <svg-icon class="edit" icon-class="edit" />
            <a-tooltip>
              <template #title>{{ curShader.comment }}</template>
              <div class="comment">{{ curShader.comment }}</div>
            </a-tooltip>
          </div>
        </div>
      </a-col>

      <a-col class="tab_box" flex="auto">
        <a-alert
          v-if="patListInfo.uploadTip"
          message="正在上传pat，刷新页面会丢失数据！"
          type="warning"
          show-icon
          :banner="true"
          class="pat_warning_tip"
        />
        <a-tabs
          v-model:activeKey="shaderHomeInfo.activeKey"
          @change="tabChange"
        >
          <a-tab-pane key="1" tab="简化任务"></a-tab-pane>
          <a-tab-pane key="2" tab="筛选任务"></a-tab-pane>
        </a-tabs>
      </a-col>
      <a-col class="right" flex="300px">
        <a-button @click="showCode">查看源码</a-button>
        <a-drawer
          wrapClassName="rays_tuner_simplifytool_code_drawer"
          placement="right"
          width="896"
          v-model:visible="codeVisible"
        >
          <div class="drawer_title">shader{{ curShader.shader_id }}源码</div>
          <div class="drawer_body">
            <code-view :codeData="codeRes" compClass="drawer_code" />
          </div>
        </a-drawer>

        <a-tooltip>
          <template #title>{{
            curShader.tag == 1 ? '取消星标Shader' : '设为星标Shader'
          }}</template>
          <svg-icon
            :icon-class="`${curShader.tag == 1 ? 'star-full' : 'star'}`"
            class="icon star"
            style="margin-left: 25px; color: #aaa"
            @click="shaderStar"
          />
        </a-tooltip>

        <div class="line" />
        <div class="right_cart">
          <my-cart />
        </div>
        <station-message class="right_bell"></station-message>

        <!-- <a-badge :count="msgCount">
                    <svg-icon class="icon" icon-class="bell" style="margin-left: 30px;color: #aaa"/>
                </a-badge> -->
      </a-col>
    </a-row>
    <a-layout>
      <router-view></router-view>
    </a-layout>
  </a-layout>
  <a-modal
    v-model:visible="remarkVisible"
    okText="保存"
    title="编辑备注"
    @ok="shaderEditOk"
  >
    <a-textarea
      v-model:value="curShader.comment"
      :maxlength="200"
      :rows="4"
      showCount
    />
  </a-modal>
  <div class="rays_tuner_simplifytool_select_box" v-show="selectShow" v-clickoutside:select_shader="()=>{selectShow = false;}">
    <div class="input_box">
      <a-input
        v-model:value="search"
        class="select-search"
        placeholder="可输入序号或备注关键词"
        @press-enter="pressEnter"
      >
        <template #prefix>
          <svg-icon class="top-icon" icon-class="search" />
        </template>
      </a-input>
    </div>
    <div class="item_box">
      <a-spin :spinning="shaderSpin">
        <RecycleScroller
          id="shader_simplifytool_home_shaders_scroll"
          class="shader_list_con"
          :items="shaderList"
          :item-size="54"
          @resize="shadersResize"
          v-slot="{ item }"
        >
          <div
            class="select_item"
            @click="shaderItemClick"
            :data-sid="item.shader_id"
          >
            <div class="title">shader_{{ item.shader_id }}</div>
            <svg-icon class="edit" icon-class="edit" />
            <div class="text">{{ item.comment }}</div>
          </div>
        </RecycleScroller>
      </a-spin>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  reactive,
  toRefs,
  computed,
  watch,
  onMounted,
  onBeforeUnmount,
  nextTick,
} from 'vue';
import router from '@/routes';
import store from '@/vuex/store';
import {
  setShader,
  setShaderHomeInfo,
  setShaderScreen,
} from '@/models/tunerStorage';
import PerfectScrollbar from 'perfect-scrollbar';
import { message } from 'ant-design-vue';
import CodeView from '@/components/CodeView';
import StationMessage from '@/views/pat-manager/StationMessage';
import MyCart from '@/views/shader-detect/MyCart.vue';
import api from '@/api/shader-tools';

export default defineComponent({
  name: 'ShaderHome',
  components: {
    CodeView,
    StationMessage,
    MyCart,
  },
  setup() {
    const $_user = store.state.user.userinfo;
    const $_email = $_user.email;
    const $_pat = computed(() => store.state.shaderTool.currentPat || {});
    let simplifyShadersDom = null;
    let simplifyShadersPs = null;
    const remarkVisible = ref(false);
    const codeVisible = ref(false);
    const codeRes = ref({ fs: '', vs: '' });
    const msgCount = ref(0);
    const shaderListState = reactive({
      shaderList: [],
      search: '',
      shaderSpin: true,
      selectShow: false,
    });
    const screenTaskList = computed(
      () => store.state.shaderTool.screenTaskList || []
    );

    const curSimplifyTask = computed(
      () => store.state.shaderTool.currentShaderSimplifyTask || { params: {} }
    );
    const curScreenTask = computed(
      () => store.state.shaderTool.currentShaderScreenTask || { params: {} }
    );

    const shaderHomeInfo = computed(
      () => store.state.shaderTool.shaderHome || {}
    );

    const patListInfo = computed(
      () => store.state.shaderTool.patListInfo || {}
    );

    if (shaderHomeInfo.value.activeKey == '1') {
      router.push({
        path: '/shaderhome/simplify',
      });
    } else if (shaderHomeInfo.value.activeKey == '2') {
      if (screenTaskList.value.length > 0) {
        router.push({
          path: '/shaderhome/screen',
        });
      }
    }

    const curShader = computed(
      () => store.state.shaderTool.currentShader || {}
    );

    watch(
      () => curShader.value.shader_id,
      () => {
        // console.log('watch curShader');
        setShaderScreen(null);
      }
    );

    const getSpecifiedShader = async () => {
      const params = {
        user: $_email,
        pat: $_pat.value.pat_name,
        shader_id: curShader.value.shader_id,
      };
      const res = await api.getSpecifiedShader(params);
      if (!res) return;
      if (res.code == 0) {
        const _info = JSON.parse(res.result);
        setShader({
          shader_id: _info.shader_id,
          comment: _info.comment,
          tag: _info.tag,
        });
      } else {
        message.error('获取Shader失败：', res.detail);
      }
    };

    if (curShader.value.comment === undefined) {
      getSpecifiedShader();
    }

    const initPageCount = async () => {
      const params = {
        user: $_email,
        pat: $_pat.value.pat_name,
      };
      const res = await api.getShaderCount(params);
      if (!res) return;
      if (res.code == 0) {
        const _list = JSON.parse(res.result_list) || [];
        if (_list.length > 0 && _list[0].device_type) {
          fetchShaders(_list[0].device_type);
        }
      } else {
        message.error('获取Shader列表总数失败');
      }
    };

    const fetchShaders = async (device) => {
      shaderListState.shaderSpin = true;
      const _search = shaderListState.search || '.*';
      let _device =
        $_pat.value.deviceType ||
        curSimplifyTask.value.params.device_type ||
        curScreenTask.value.params.device_type ||
        '';
      if (device) {
        _device = device;
      }
      const countParams = {
        user: $_email,
        pat: $_pat.value.pat_name,
        plain_pattern: _search,
        device_type: _device,
        tag_pattern: '.*',
      };
      const constRes = await api.getShaderCountWithFeature(countParams);
      if (!constRes) return;
      if (constRes.code != 0) {
        message.error('获取Shader列表总数失败');
        return;
      }
      const _total = constRes.count || 0;
      if (_total > 0) {
        const listParams = {
          user: $_email,
          pat: $_pat.value.pat_name,
          plain_pattern: _search,
          device_type: _device,
          tag_pattern: '.*',
          offset: 0,
          count: _total,
        };
        const listRes = await api.getPidInListWithFeature(listParams);
        if (!listRes) return;
        if (listRes.code != 0) {
          message.error('获取Shader列表失败：' + listRes.detail);
          return;
        }
        let _list = JSON.parse(listRes.result_list);
        for (let i = 0; i < _list.length; i++) {
          _list[i].id = i + 1;
        }
        shaderListState.shaderList = _list;
        shaderListState.shaderSpin = false;
        if (simplifyShadersPs && simplifyShadersDom) {
          nextTick(() => {
            simplifyShadersPs.update();
          });
        }
      } else {
        if (_search == '.*' && !device) {
          initPageCount();
        } else {
          shaderListState.shaderList = [];
          shaderListState.shaderSpin = false;
          if (simplifyShadersPs && simplifyShadersDom) {
            nextTick(() => {
              simplifyShadersPs.update();
            });
          }
        }
      }
    };

    const selectChange = () => {
      shaderListState.selectShow = !shaderListState.selectShow;
      if (shaderListState.selectShow) {
        fetchShaders();
      }
    };

    const pressEnter = () => {
      fetchShaders();
    };

    const shaderItemClick = (event) => {
      // console.log('shaderItemClick', event);
      for (let i = 0; i < event.path.length; i++) {
        if (event.path[i].className == 'select_item') {
          let _shaderid = event.path[i].dataset.sid;
          for (let i = 0; i < shaderListState.shaderList.length; i++) {
            if (shaderListState.shaderList[i].shader_id == _shaderid) {
              let _shader = shaderListState.shaderList[i];
              setShader(_shader);
              shaderListState.selectShow = false;
              // console.log('_shader', _shader);
              break;
            }
          }
          break;
        }
      }
    };

    const shadersResize = () => {
      // console.log('framesResize:');
      // 初始化shader列表scroll
      if (!simplifyShadersDom || !simplifyShadersPs) {
        simplifyShadersDom = document.querySelector(
          '#shader_simplifytool_home_shaders_scroll'
        );
        if (!simplifyShadersPs) {
          simplifyShadersPs = new PerfectScrollbar(simplifyShadersDom);
        }
      }
    };

    const remarkClick = () => {
      remarkVisible.value = true;
    };

    const editShader = async (shaderId, { type, comment, tag } = {}) => {
      if (!shaderId && shaderId !== 0) return;

      const params = {
        user: $_email,
        pat: $_pat.value.pat_name,
        shader_id: shaderId,
        type: type || 0,
      };

      let _shader = curShader.value;

      if (type === 0) {
        params.comment = comment;
        _shader.comment = comment;
      } else if (type === 1) {
        params.tag = tag;
        _shader.tag = tag;
      }

      const res = await api.editShader(params);
      if (!res) return;
      if (res.code == 0) {
        setShader(_shader);
        message.success('更新Shader成功！');
      } else {
        message.error('更新Shader失败：', res.detail);
      }
    };

    const shaderEditOk = () => {
      remarkVisible.value = false;
      editShader(curShader.value.shader_id, {
        type: 0,
        comment: curShader.value.comment,
      });
    };

    const shaderStar = () => {
      editShader(curShader.value.shader_id, {
        type: 1,
        tag: curShader.value.tag == 1 ? 0 : 1,
      });
    };

    const tabChange = (value) => {
      // console.log('tabChange', value);
      const _homeinfo = JSON.parse(JSON.stringify(shaderHomeInfo.value));
      _homeinfo.activeKey = value;

      if (shaderHomeInfo.value.activeKey == '1') {
        router.push({
          path: '/shaderhome/simplify',
        });
      } else if (shaderHomeInfo.value.activeKey == '2') {
        // console.log('screenTaskList', screenTaskList.value);
        if (screenTaskList.value.length > 0) {
          router.push({
            path: '/shaderhome/screen',
          });
        } else {
          _homeinfo.activeKey = '1';
          message.warning('此Shader没有筛选任务！');
        }
      }
      setShaderHomeInfo(_homeinfo);
    };

    const getOriginalShaderCode = async () => {
      const params = {
        user: $_email,
        pat: $_pat.value.pat_name,
        pid: curShader.value.shader_id,
      };
      const res = await api.getOriginalShaderCode(params);
      if (!res) return;
      if (res.code == 0) {
        codeRes.value = {
          fs: res.fs || '',
          vs: res.vs || '',
        };
      } else {
        message.error('获取源码失败：', res.detail);
      }
    };
    const showCode = () => {
      codeVisible.value = true;
    };

    const simplifyHome = computed(
      () => store.state.shaderTool.simplifyHome || {}
    );
    const pre = () => {
      // console.log('pre');
      // router.go(-1)
      const _homeinfo = JSON.parse(JSON.stringify(shaderHomeInfo.value));
      _homeinfo.activeKey = '1';
      setShaderHomeInfo(_homeinfo);
      setShaderScreen(null);
      switch (simplifyHome.value.menuKey[0]) {
        case '1':
          break;
        case '2':
          router.push({
            path: '/mainhome/simplifyhome/shader',
          });
          break;
        case '3':
          router.push({
            path: '/mainhome/simplifyhome/simplifytask',
          });
          break;
        case '4':
          router.push({
            path: '/mainhome/simplifyhome/screentask',
          });
          break;
        case '5':
          router.push({
            path: '/mainhome/simplifyhome/imagequality',
          });
          break;
        default:
          break;
      }
      // router.push({
      //     path: '/mainhome/simplifyhome/shader'
      // });
    };

    onMounted(() => {
      // console.log(process.env.NODE_ENV);
    });
    onBeforeUnmount(() => {
      if (simplifyShadersPs) {
        simplifyShadersPs.destroy();
        simplifyShadersPs = null;
      }
      simplifyShadersDom = null;
    });

    getOriginalShaderCode();

    return {
      ...toRefs(shaderListState),
      curShader,
      remarkVisible,
      codeVisible,
      msgCount,
      shaderHomeInfo,
      patListInfo,
      codeRes,
      shaderStar,
      fetchShaders,
      shadersResize,
      selectChange,
      shaderItemClick,
      pressEnter,
      remarkClick,
      shaderEditOk,
      tabChange,
      showCode,
      pre,
    };
  },
});
</script>

<style lang="scss" scoped>
.header {
  height: 48px;
  background-color: #fff;
  border-bottom: 1px solid #d8d8d8;
}

.back-icon {
  color: #aaa;
  position: absolute;
  left: 28px;
  top: 12px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.select_shader {
  position: relative;
  float: left;
  margin-left: 68px;
  line-height: 48px;
  padding-right: 18px;
  cursor: pointer;

  span {
    font-size: 14px;
    color: #3c3c3c;
  }

  .down {
    width: 16px;
    height: 14px;
    margin-left: 8px;
    vertical-align: -2px;
  }

  :after {
    content: '';
    width: 1px;
    height: 16px;
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -8px;
    background-color: #d8d8d8;
  }
}

.tips {
  line-height: 48px;
  display: inline-block;
  margin-left: 18px;
  font-size: 12px;
  color: #666;
  cursor: pointer;
  .comment {
    display: inline-block;
    max-width: 200px;
    height: 48px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.edit {
  width: 14px;
  height: 14px;
  color: #666;
  vertical-align: 17px;
}

.pat_warning_tip {
  position: absolute;
  margin-top: 5px;
}

.right {
  line-height: 48px;

  .icon {
    vertical-align: -6px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  .star:focus {
    outline: none;
  }

  .line {
    display: inline-block;
    width: 1px;
    height: 16px;
    margin: 0 26px;
    background-color: #d8d8d8;
    vertical-align: -2px;
  }
  .right_cart {
    display: inline-block;
    margin-left: -16px;
  }
}

.rays_tuner_simplifytool_select_box {
  position: fixed;
  left: 40px;
  top: 48px;
  width: 336px;
  height: 368px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.16);
}

.item_box {
  height: 320px;
  overflow-y: auto;
  overflow-x: hidden;
  .ant-spin-nested-loading {
    height: 100%;
  }
  .shader_list_con {
    position: relative;
    height: 100%;
  }
}
.top-icon {
  width: 16px;
  height: 16px;
}

.input_box {
  margin: 8px;
}

.select-search {
  height: 32px;
  border-radius: 4px;
}

.select_item {
  height: 54px;
  background-color: #fff;
  padding: 4px 16px;
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
  }

  .title {
    font-size: 14px;
    color: #3c3c3c;
  }

  .edit {
    float: left;
    margin-top: 2px;
  }

  .text {
    width: 285px;
    display: inline-block;
    color: #666;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
<style lang="scss">
.rays_tuner_simplifytool_home {
  height: 100vh;
  .tab_box {
    .ant-tabs-nav .ant-tabs-tab-active,
    .ant-tabs-nav .ant-tabs-tab:hover {
      color: #604bdc;
    }

    .ant-tabs-ink-bar {
      background-color: #604bdc;
    }

    .ant-tabs-nav-wrap {
      text-align: center;
    }
  }

  .ant-btn {
    color: #8c8c8c;
    border-color: #d8d8d8;
    padding: 0 20px;
  }

  .right_cart {
    .ant-badge {
      vertical-align: -5px;
    }
  }

  .right_bell {
    padding: 19px 8px 9px 12px;
    // margin-left: 10px;
    vertical-align: -5px;
  }
}
.rays_tuner_simplifytool_select_box {
  .ant-spin-container {
    height: 100%;
  }
}

.rays_tuner_simplifytool_code_drawer {
  .ant-drawer-body {
    height: 100%;
    .drawer_title {
      height: 24px;
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-Bold;
      font-weight: 700;
      text-align: LEFT;
      color: #222;
      line-height: 24px;
    }
    .drawer_body {
      height: calc(100% - 48px);
      margin-top: 24px;
    }
  }
}
</style>
